import { useTranslation } from 'next-i18next'
import styled from 'styled-components'

import { Label } from '@/components/text'

import { Content, ContentWrapper, Header, Wrapper } from './elements'

const LoadingLabel = styled(Label)`
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loading = () => {
  const { t } = useTranslation()
  return (
    <Wrapper>
      <Header>
        <Label fontSize="18px" type="large">
          {t('filters:b.listing_filter.edit.filters.label', 'Filters')}
        </Label>
      </Header>
      <Content>
        <ContentWrapper>
          <LoadingLabel>
            {t(
              'filters:b.listings.view.loading_filters.text',
              'Loading Filters'
            )}
          </LoadingLabel>
        </ContentWrapper>
      </Content>
    </Wrapper>
  )
}

export default Loading
