import { zhCN } from 'date-fns/locale'

const LANGUAGES_MAP = {
  zh: zhCN
}

function getDataFnsLocale(locale) {
  return LANGUAGES_MAP[locale] || null
}

export default getDataFnsLocale
