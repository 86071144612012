import React, { ComponentType, createContext, useContext } from 'react'

// Workaround to pass props to nextjs loading components using next/dynamic
// https://github.com/kevin940726/next-dynamic-loading-props

function withLoadingProps<Props extends JSX.IntrinsicAttributes = {}>(
  loader: (useLoadingProps: () => Props) => ComponentType<Props>
): ComponentType<Props> {
  const LoadingPropsContext = createContext({} as Props)

  const useLoadingProps = () => useContext(LoadingPropsContext)

  const Dynamic = loader(useLoadingProps)

  return function Loading(props: Props) {
    return (
      <LoadingPropsContext.Provider value={props}>
        <Dynamic {...props} />
      </LoadingPropsContext.Provider>
    )
  }
}

export default withLoadingProps
