import { path } from 'ramda'
import { useSelector } from 'react-redux'

import { formatFilters } from '@/models/listings'
import { CURRENT_LOCATION } from '@/utils/location'

const useFormattedFilters = () => {
  const filters = useSelector(path(['listings', 'filters']))
  const format = formatFilters(filters)
  const filtersLocation = {
    ...format,
    cities: filters.cities.filter(el => el !== CURRENT_LOCATION)
  }
  return filtersLocation
}

export default useFormattedFilters
