import { useTranslation } from 'next-i18next'
import { Box, Text, TooltipRenderer } from '@machobear/design-system'

import { isEmptyOrNull } from '@/utils'
import { useAlertModalContext } from '@/hooks/use-alert-modal-context'
import useFormattedFilters from '@/hooks/use-formatted-filters'

const makeAlertButton = (Component, name, insideReactSelect) => {
  const AlertButton = ({ id, ...rest }) => {
    const filters = useFormattedFilters()
    const disabled = isEmptyOrNull(filters.cities)
    const { t } = useTranslation()
    const { setModalVisible } = useAlertModalContext()
    const onClick = () => {
      if (disabled) return
      window.dataLayer.push({
        event: id
      })
      setModalVisible(true)
    }
    return (
      <>
        {disabled ? (
          <TooltipRenderer
            id="createAlertTooltip"
            placement="bottom"
            tooltip={
              <Box>
                <Text weight="regular" size="small">
                  {t(
                    'filters:b.listing.alert.create.disabled.tooltip',
                    'Search a city to create an alert'
                  )}
                </Text>
              </Box>
            }
          >
            {({ triggerProps }) => (
              <Box
                display="flex"
                alignItems="center"
                aria-label={t(
                  'filters:b.listing.alert.create.disabled.tooltip',
                  'Search a city to create an alert'
                )}
                {...triggerProps}
              >
                <Component
                  id={id}
                  disabled={disabled}
                  {...rest}
                  onClick={insideReactSelect ? undefined : onClick}
                  onMouseDown={insideReactSelect ? onClick : undefined}
                  onTouchEnd={insideReactSelect ? onClick : undefined}
                />
              </Box>
            )}
          </TooltipRenderer>
        ) : (
          <Component
            id={id}
            disabled={disabled}
            {...rest}
            onClick={insideReactSelect ? undefined : onClick}
            // onMouseDown/onTouchEnd instead of onClick due this component
            // being used within react-select which does weird things
            // https://github.com/JedWatson/react-select/issues/3117
            onMouseDown={insideReactSelect ? onClick : undefined}
            onTouchEnd={insideReactSelect ? onClick : undefined}
          />
        )}
      </>
    )
  }
  AlertButton.displayName = name || 'AlertButton'
  return AlertButton
}

export default makeAlertButton
