import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import {
  anyPass,
  append,
  assoc,
  flatten,
  includes,
  isEmpty,
  isNil,
  join,
  keys,
  map,
  omit,
  pickAll,
  pipe,
  prop,
  propEq,
  reject,
  values
} from 'ramda'

import { formatDate } from '@/utils/date'
import formatCurrency from '@/utils/format-currency'

const useFilterLabels = () => {
  const { t } = useTranslation()
  const router = useRouter()

  const formatBedrooms = value => {
    // Handle old values that may not be an array
    const array = Array.isArray(value) ? value : [value]
    if (array.length === 1 && includes('0', array)) {
      return t('common.studio', 'Studio')
    }
    const label = array
      .map(val => {
        if (val === '0') return t('common.studio', 'Studio')
        return val
      })
      .join(', ')
    return t('b.listing.view.count_beds.text', {
      bedroomCount: label,
      defaultValue: '{{ bedroomCount }} Bed'
    })
  }

  const formatBathrooms = value => {
    // Handle old values that may not be an array
    const array = Array.isArray(value) ? value : [value]
    const label = array.join(', ')
    return t('b.listing.view.count_baths.text', {
      defaultValue: '{{ bathroomCount }} Bath',
      bathroomCount: label
    })
  }

  const formatPrice = value => {
    const MAX_PRICE = 10000

    if (!value.max || value.max >= MAX_PRICE) {
      return `${formatCurrency(value.min)}+`
    }
    if (!value.min || value.min === 0) {
      return `$0 - ${formatCurrency(value.max)}`
    }
    return `${formatCurrency(value.min)} - ${formatCurrency(value.max)}`
  }

  const formatAvailability = ({ date, custom_date }) => {
    if (date === 'NOW')
      return t(
        'filters:b.listing_filter.edit.available_now.text',
        'Available Now'
      )
    if (date === 'NEXT_MONTH')
      return t(
        'filters:b.listing_filter.edit.available_next_month.text',
        'Available Next Month'
      )
    if (date === 'CUSTOM')
      return custom_date
        ? t('filters:b.listing_filter.edit.available_before.text', {
            defaultValue: 'Before {{ customDate }}',
            customDate: formatDate(custom_date, 'MMM d, yyyy', router?.locale)
          })
        : null
  }

  const SORT_BY = {
    suggested_asc: t(
      'filters::b.listing_filter.edit.suggested.text',
      'Suggested'
    ),
    created_at_desc: t(
      'filters:b.listing_filter.edit.created_at_newest.text',
      'Date: Newest to Oldest'
    ),
    created_at_asc: t(
      'filters:b.listing_filter.edit.created_at_oldest.text',
      'Date: Oldest to Newest'
    ),
    price_desc: t(
      'filters:b.listing_filter.edit.price_highest.text',
      'Price: High to Low'
    ),
    price_asc: t(
      'filters:b.listing_filter.edit.price_lowest.text',
      'Price: Low to High'
    )
  }

  const formatSort = value => {
    const sortByFilter = join('_', [
      value.sort_by,
      value.sort_type
    ]).toLowerCase()
    return SORT_BY[sortByFilter]
  }

  const formatMinSize = value => {
    return t('filter:b.listing_filter.edit.min_size_sqft.text', {
      defaultValue: '{{ minSize }} Sqft +',
      minSize: value
    })
  }

  const formatDistance = value => {
    return t('filter:b.listing_filter.edit.distance.text', {
      defaultValue: 'Within {{ distance }}',
      distance: value
    })
  }

  const formatTransportation = value => {
    return t('filter:b.listing_filter.edit.transportation.text', {
      defaultValue: '{{ distance }} to Transit',
      distance: value
    })
  }

  const formatArray = (array, labels) => {
    // Handle old values that may not be an array
    const safeArray = Array.isArray(array) ? array : [array]
    return safeArray.map(id => {
      const label = labels[id]
      if (!label) return []
      return {
        id,
        label: labels[id]
      }
    })
  }

  const HOUSING_TYPES = {
    HIGHRISE: t(
      'filters:b.listing_filter.edit.housing_type_apartment.text',
      'Apartment'
    ),
    HOUSE: t('filters:b.listing_filter.edit.housing_type_house.text', 'House'),
    TOWNHOUSE: t(
      'filters:b.listing_filter.edit.housing_type_townhouse.text',
      'Townhouse'
    )
  }

  const UNIT_TYPES = {
    SINGLE: t('filters:b.listing_filter.edit.housing_type_room.text', 'Room'),
    PARTIAL: t(
      'filters:b.listing_filter.edit.housing_type_partial.text',
      'Partial Space'
    ),
    ENTIRE: t(
      'filters:b.listing_filter.edit.housing_type_entire.text',
      'Entire Space'
    )
  }

  const LEASE_TYPES = {
    MONTH_TO_MONTH: t(
      'filters:b.listing_filter.edit.lease_type_month_to_month.text',
      'Month to Month'
    ),
    FIXED: t(
      'filters:b.listing_filter.edit.lease_type_fixed_term.text',
      'Fixed Term'
    )
  }

  const PETS = {
    DOGS_ALLOWED: t(
      'filters:b.listing_filter.edit.pet_policy_dogs_allowed.text',
      'Dogs Allowed'
    ),
    CATS_ALLOWED: t(
      'filters:b.listing_filter.edit.pet_policy_cats_allowed.text',
      'Cats Allowed'
    ),
    ALLOWED: t(
      'filters:b.listing_filter.edit.pet_policy_allowed.text',
      'Pets Allowed'
    ),
    NOT_ALLOWED: t(
      'filters:b.listing_filter.edit.pet_policy_not_allowed.text',
      'Pets Not Allowed'
    )
  }

  const RENTAL_TYPES = {
    SHORT_TERM: t(
      'filters:b.listing_filter.edit.lease_type_short_term.text',
      'Short Term'
    ),
    LONG_TERM: t(
      'filters:b.listing_filter.edit.lease_type_long_term.text',
      'Long Term'
    )
  }

  const FEATURES = {
    air_conditioning: t(
      'filters:b.listing_filter.edit.air_conditioning.label',
      'Air Conditioning'
    ),
    balcony: t('filters:b.listing_filter.edit.balcony.label', 'Balcony'),
    dishwasher: t(
      'filters:b.listing_filter.edit.dishwasher.label',
      'Dishwasher'
    ),
    gym: t('filters:b.listing_filter.edit.gym.label', 'Gym'),
    insuite_laundry: t(
      'filters:b.listing_filter.edit.insuite_laundry.label',
      'Insuite Laundry'
    ),
    parking: t('filters:b.listing_filter.edit.parking.label', 'Parking'),
    // private_bathroom: t(
    //   'filters:b.listing_filter.edit.private_bathroom.label',
    //   'Private Bathroom'
    // ),
    pool: t('filters:b.listing_filter.edit.pool.label', 'Pool'),
    disable_ready: t(
      'filters:b.listing_filter.edit.disable_ready.label',
      'Wheelchair Accessible'
    ),
    furnished: t('common.furnished', 'Furnished'),
    unfurnished: t('common.unfurnished', 'Unfurnished')
  }

  const UTILITIES = {
    heat: t('filters:b.listing_filter.edit.heat.label', 'Heating'),
    water: t('filters:b.listing_filter.edit.water.label', 'Water'),
    electricity: t(
      'filters:b.listing_filter.edit.electricity.label',
      'Hydro / Electricity'
    ),
    gas: t('filters:b.listing_filter.edit.gas.label', 'Natural Gas'),
    internet: t('filters:b.listing_filter.edit.internet.label', 'Wifi'),
    cable: t('filters:b.listing_filter.edit.cable.label', 'Cable TV')
  }

  const labels = {
    SORT_BY,
    HOUSING_TYPES,
    UNIT_TYPES,
    LEASE_TYPES,
    PETS,
    RENTAL_TYPES,
    FEATURES,
    UTILITIES
  }

  const getFilterLabels = filters => {
    // Pick only filters that we want to display
    const pickFilters = pickAll(
      [
        'housing_types',
        'unit_types',
        'bedroom_count',
        'bathroom_count',
        'pet_policy',
        'min_price',
        'max_price',
        'availability_date',
        'custom_availability_date',
        'search_only_verified',
        'sort',
        'min_size',
        'lease_type',
        'rental_type',
        'has_3d_tour',
        'features',
        'utilities',
        'distance',
        'transportation'
      ],
      filters
    )
    // Remove all null or empty filters
    const activeFilters = reject(anyPass([isNil, isEmpty]), pickFilters)
    // Merge min_price and max_price into one key
    const filtersWithPrice = pipe(
      assoc('price', {
        min: activeFilters.min_price || null,
        max: activeFilters.max_price || null
      }),
      // Merge availability_date and custom_availability_date into one key
      assoc('availability', {
        date: activeFilters.availability_date || null,
        custom_date: activeFilters.custom_availability_date || null
      }),
      omit([
        'min_price',
        'max_price',
        'availability_date',
        'custom_availability_date'
      ])
    )(activeFilters)
    // Remove certain filter values we do not want to display
    const activeFiltersList = Object.keys(filtersWithPrice).map(key => {
      return { [key]: filtersWithPrice[key] }
    })
    const withoutUnwantedValues = reject(
      anyPass([
        propEq('search_only_verified', false),
        propEq('price', { min: null, max: null }),
        propEq('availability', { date: null, custom_date: null }),
        propEq('has_3d_tour', false)
      ]),
      activeFiltersList
    )
    // Format final filters list
    const formattedFiltersList = map(x => {
      return {
        id: keys(x)[0],
        value: values(x)[0]
      }
    })(withoutUnwantedValues)
    // Get display text for each filter
    return flatten(
      formattedFiltersList.map(({ id, value }) => {
        switch (id) {
          case 'housing_types':
            return formatArray(value, HOUSING_TYPES)
          case 'unit_types':
            return formatArray(value, UNIT_TYPES)
          case 'bedroom_count':
            return {
              id,
              label: formatBedrooms(value)
            }
          case 'bathroom_count':
            return {
              id,
              label: formatBathrooms(value)
            }
          case 'price':
            return {
              id,
              label: formatPrice(value)
            }
          case 'pet_policy':
            return formatArray(value, PETS)
          case 'availability':
            return {
              id,
              label: formatAvailability(value)
            }
          case 'search_only_verified':
            return {
              id,
              label: t(
                'filters:b.listing_filter.edit.verified_only.label',
                'Verified Only'
              )
            }
          case 'sort':
            return {
              id,
              label: formatSort(value)
            }
          case 'min_size':
            return {
              id,
              label: formatMinSize(value)
            }
          case 'lease_type':
            return {
              id,
              label: LEASE_TYPES[value]
            }
          case 'rental_type':
            return formatArray(value, RENTAL_TYPES)
          case 'has_3d_tour':
            return {
              id,
              label: t(
                'filters:b.listing_filter.edit.3d_video_tour.label',
                '3D / Video Tour'
              )
            }
          case 'features':
            return formatArray(value, FEATURES)
          case 'utilities':
            return formatArray(value, UTILITIES)
          case 'distance':
            return {
              id,
              label: formatDistance(value)
            }
          case 'transportation':
            return {
              id,
              label: formatTransportation(value)
            }
          default:
            return {
              id,
              label: value
            }
        }
      })
    )
  }

  const formatCities = cities => {
    if (!cities || cities.length <= 0) return ''
    const cityList = cities.join(', ')
    return `${t(
      'filters:b.listing.alert.summary.city_in.text',
      'In:'
    )} ${cityList}`
  }

  const getFilterSummary = filters => {
    if (!filters) return ''
    const filterLabels = getFilterLabels(filters)
    const cityList = formatCities(filters.cities)
    const filtersSummary = pipe(
      reject(propEq('id', 'sort')),
      map(prop('label')),
      append(cityList),
      join(', ')
    )(filterLabels)
    return filtersSummary
  }

  return [getFilterLabels, getFilterSummary, labels]
}

export default useFilterLabels
