import styled from 'styled-components'
import { vars } from '@machobear/design-system/css'

import { breakpoints, colors } from '@/styles'
import { Label } from '@/components/text'

export const Wrapper = styled.div`
  font-size: 16px;
  line-height: 20px;
  font-family: 'Inter';
  color: ${colors.black};
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: ${colors.white};
  border-bottom: 1px solid ${colors.grey88};
  width: 100%;
  padding: 24px 24px 16px;
  position: sticky;
  top: 0;
  z-index: 1;
  border-radius: 20px 20px 0 0;
`

export const Content = styled.div`
  overflow: none;
  display: flex;
  flex-direction: column;
  padding: 24px 32px;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    padding: 24px 24px 100px;
  }
`

export const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const FilterGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;
  border-bottom: 1px solid ${vars.borderColor.neutralLight};
  padding-bottom: 24px;
  margin-bottom: 24px;
  width: 100%;

  @media screen and (max-width: ${breakpoints.ipadMiniMax}) {
    grid-template-columns: 1fr;
    gap: 24px;
  }

  ${props =>
    props.noBorder &&
    `
    border-bottom: none;
    padding-bottom: 0;
    `}
`

export const FilterItem = styled.div`
  grid-column: span ${props => props.span || 1};
`

export const FilterLabel = styled(Label)`
  display: flex;
  align-items: center;
  color: ${vars.foregroundColor.neutral};
  line-height: 24px;
  margin-bottom: ${props => props.bottom || 16}px;

  svg {
    color: ${vars.foregroundColor.tertiary};
  }
`
