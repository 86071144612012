import { useRouter } from 'next/router'
import { useTranslation } from 'next-i18next'
import { pickAll } from 'ramda'

import { useFilters } from '@/hooks/use-filters'
import { show } from '@/features/Listings/listing-filter/filters-modal'

export const useFiltersModal = ({
  isMapMode,
  boundaries,
  currentLocation,
  hideResultsCount,
  isUniversityPage,
  centerPoint
}) => {
  const { t } = useTranslation()
  const router = useRouter()
  const [filters, setFilter] = useFilters()
  const filterModalValues = pickAll(
    [
      'cities',
      'housing_types',
      'unit_types',
      'bedroom_count',
      'bathroom_count',
      'pet_policy',
      'min_price',
      'max_price',
      'availability_date',
      'custom_availability_date',
      'search_only_verified',
      'sort',
      'min_size',
      'lease_type',
      'rental_type',
      'has_3d_tour',
      'features',
      'utilities',
      'distance',
      'location',
      'transportation'
    ],
    filters
  )
  const setFilterMerging = newFilter => {
    const filterParams = { ...newFilter, page: null }
    setFilter(filterParams)
  }

  const showFiltersModal = () => {
    show({
      t,
      router,
      value: filterModalValues,
      onChange: setFilterMerging,
      isMapMode,
      boundaries,
      currentLocation,
      hideResultsCount,
      isUniversityPage,
      centerPoint
    })
  }

  return [showFiltersModal]
}
