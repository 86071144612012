import dynamic from 'next/dynamic'

import withLoadingProps from '@/utils/loading-props'
import { createModal } from '@/components/modalize'

import Loading from './loading'

const FiltersModal = withLoadingProps(loadingProps =>
  dynamic(() => import('./base'), {
    ssr: false,
    loading: () => {
      const props = loadingProps()
      return <Loading {...props} />
    }
  })
)

export const show = createModal(FiltersModal, {
  width: '100%',
  maxWidth: '787px',
  showClose: false
})
