import { differenceInMilliseconds, format } from 'date-fns'

import getDataFnsLocale from '@/hooks/use-datefns-locale'

// Converts dates with hyphens (eg "YYYY-MM-DD HH:MM:SS") to use slashes
// (eg "YYYY/MM/DD HH:MM:SS") to prevent parsing issues with JavaScriptCore (Safari)
export const parseDate = date => {
  if (!date) return ''
  return date
    .toString()
    .replace(/([0-9]{4})-([0-9]{2})-([0-9]{2})(.*)/, '$1/$2/$3$4')
}

export const formatDate = (date, formatStr = 'dd/MM/yyyy hh:mm a', locale) => {
  if (!date) return ''
  return format(new Date(parseDate(date)), formatStr, {
    locale: getDataFnsLocale(locale)
  })
}

export const isTodayOrPast = date =>
  differenceInMilliseconds(new Date(), new Date(parseDate(date))) >= 0
